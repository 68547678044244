import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ServicesGrid from "../components/servicesGrid";

const AboutPage = () => (
  <Layout>
    <SEO
      title="About"
      description="At the core of our project delivery is a team of highly
                qualified civil staff that combine technical knowledge,
                specialist skills and practical experience to consistently
                deliver quality outcomes for our projects."
      image={"/driveay.jpg"}
    />

    <div className="row g-0">
      <div className="col-lg-7 d-flex align-items-center justify-content-center">
        <div className="content-box">
          <h1 className="section-heading section-heading--left mb-lg-5">
            About us
          </h1>
          <p>
            Sierra Delta Civil Ltd is a civil contracting company delivering a
            wide range of multi civil construction projects for local government
            agencies, commercial clients and private residential clients
            throughout the wider Wellington region.
          </p>
          <p>
            At the core of our project delivery is a team of highly qualified
            civil staff that combine technical knowledge, specialist skills and
            practical experience to consistently deliver quality outcomes for
            our projects.
          </p>
          <p>
            Our team can oversee your project from beginning to end. From
            construction through to sign off, our teams expert construction
            knowledge helps to deliver solutions for our clients and our
            management experience ensure projects are programmed with realistic
            deadlines, project works are managed safely, and resources are
            adequately allocated.
          </p>
          <p>
            No project is considered too small or too large for our team who
            cater for all civil construction requirements. Our team strives to
            achieve client satisfaction on all projects and work to build strong
            relationships with clients to foster a cooperative working
            environment.
          </p>
        </div>
      </div>
      <div className="col-lg-5 d-flex align-items-center">
        <StaticImage
          src="../images/driveway.jpg"
          aspectRatio={12 / 14}
          alt="Sierra Delta crew excavating and widening a driveway in Maungaraki."
        />
      </div>
    </div>
  </Layout>
);

export default AboutPage;
