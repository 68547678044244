import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export const ServicesGrid = () => (
  <section id="services" className="bg-dark text-white">
    <div className="container">
      <h2 className="section-heading">Services</h2>
      <div className="row">
        <div className="col-md-6 col-lg-4 mb-4">
          <Link to="/concrete/">
            <div className="project-tile ">
              <StaticImage
                aspectRatio={16 / 10}
                src="../images/concrete.jpg"
                alt="Concrete path"
              />
              <h5>
                Concrete
                <i className="fas fa-arrow-right"></i>
              </h5>
            </div>
          </Link>
        </div>
        <div className="col-md-6 col-lg-4 mb-4">
          <Link to="/asphalt/">
            <div className="project-tile ">
              <StaticImage
                aspectRatio={16 / 10}
                src="../images/asphalt.jpg"
                alt="Asphalt subdivision"
              />
              <h5>
                Asphalt
                <i className="fas fa-arrow-right"></i>
              </h5>
            </div>
          </Link>
        </div>
        <div className="col-md-6 col-lg-4 mb-4">
          <Link to="/utility-installation/">
            <div className="project-tile ">
              <StaticImage
                aspectRatio={16 / 10}
                src="../images/utility-installation.jpg"
                alt="Utility Installation"
              />
              <h5>
                Utility Installation
                <i className="fas fa-arrow-right"></i>
              </h5>
            </div>
          </Link>
        </div>
        <div className="col-md-6 col-lg-4 mb-4">
          <Link to="/water-reticulation/">
            <div className="project-tile ">
              <StaticImage
                aspectRatio={16 / 10}
                transformOptions={{ cropFocus: "south" }}
                src="../images/water-reticulation.jpg"
                alt="water reticulation"
              />
              <h5>
                Water Reticulation
                <i className="fas fa-arrow-right"></i>
              </h5>
            </div>
          </Link>
        </div>
        <div className="col-md-6 col-lg-4 mb-4">
          <Link to="/drainage/">
            <div className="project-tile ">
              <StaticImage
                aspectRatio={16 / 10}
                src="../images/drainage.jpg"
                transformOptions={{ cropFocus: "center" }}
                alt="Digging a trench"
              />
              <h5>
                Drainage
                <i className="fas fa-arrow-right"></i>
              </h5>
            </div>
          </Link>
        </div>
        <div className="col-md-6 col-lg-4 mb-4">
          <Link to="/subdivisions/">
            <div className="project-tile ">
              <StaticImage
                aspectRatio={16 / 10}
                transformOptions={{ cropFocus: "south" }}
                objectPosition="50% 50%"
                src="../images/sub-divisions.jpg"
                alt="Subdivision"
              />
              <h5>
                Subdivisions
                <i className="fas fa-arrow-right"></i>
              </h5>
            </div>
          </Link>
        </div>
        <div className="col-md-6 col-lg-4 mb-4">
          <Link to="/construction/">
            <div className="project-tile ">
              <StaticImage
                aspectRatio={16 / 10}
                src="../images/construction.jpg"
                alt="Sierra Delta sign on construction fence"
              />
              <h5>
                Civil Construction
                <i className="fas fa-arrow-right"></i>
              </h5>
            </div>
          </Link>
        </div>
        <div className="col-md-6 col-lg-4 mb-4">
          <Link to="/earthworks/">
            <div className="project-tile ">
              <StaticImage
                aspectRatio={16 / 10}
                transformOptions={{ cropFocus: "center" }}
                objectPosition="50% 50%"
                src="../images/earthworks.jpg"
                alt="Digger"
              />
              <h5>
                Earthworks
                <i className="fas fa-arrow-right"></i>
              </h5>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default ServicesGrid;
